import React from "react"
import styles from "./pagination.module.scss"
import { Link } from "gatsby"

const Pagination = ({ total, current, limit }) => {
  const totalPages = Math.ceil(total / limit)
  const numbers = []
  for (let i = 1; i <= totalPages; i++) {
    numbers.push(i)
  }
  return (
    <div className={styles.pagination}>
      {numbers.map((number, i) => (
        <div key={i} className={number === current ? styles.number + " " + styles.selectedNumber : styles.number}><Link
          to={"/posts/" + number}>{number}</Link></div>
      ))}
    </div>
  )
}

export default Pagination
