import React from "react"
import Layout from "../components/layout"
import { graphql, navigate, Link } from "gatsby"
import styles from "./posts.module.scss"
import Pagination from "../components/pagination"

const gotoLink = (link) => {
  navigate("/" + link + "/")
}

const Posts = ({ data, pageContext }) => {
  const posts = data.allWpPost.edges
  return (
    <Layout>
      <div className={"container " + styles.posts}>
        <h1>Blog Posts</h1>
        {posts.map((item, i) => (
          <div className={styles.post} key={i}>
            <div className={styles.title}>
              <Link to={item.node.uri}>
                {item.node.title}
              </Link>
            </div>
            <div><p dangerouslySetInnerHTML={{ __html: item.node.excerpt }} /></div>
            <button className={"button is-primary is-rounded"} onClick={() => gotoLink(item.node.slug)}> Read More
            </button>
          </div>
        ))}
        <Pagination total={pageContext.total} current={pageContext.displayPageBlog} limit={pageContext.limit} />
      </div>
    </Layout>
  )
}

export default Posts

export const query = graphql`
    query($limit: Int, $pageNumber: Int){
        allWpPost(limit: $limit, skip: $pageNumber) {
            edges {
                node {
                    id
                    title
                    uri
                    slug
                    status
                    modified
                    excerpt
                }
            }
        }
    }
`
